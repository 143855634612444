import React from 'react';

import { Outlet } from 'react-router-dom';

import './Layout.css';

function Layout() {
  return (
    <div className="layout-parent-div">
      <Outlet />
    </div>
  );
}

export default Layout;
